import { IAddressObject } from "./parseGeocodeLocationToAddress";

function formatAddress(addressObject: IAddressObject): string {
  const { street, city, state, zip } = addressObject;
  const valuesToBeEvaluated = {
    street,
    city,
    state,
    zip,
  };

  if (!street || !city || !state || !zip) return "";

  if (
    Object.values(valuesToBeEvaluated)
      .flat()
      .every(v => typeof v === "string" && !v.length)
  ) {
    return "";
  }
  return `${addressObject.street} ${addressObject.city}, ${addressObject.state}, ${addressObject.zip}`;
}

export { formatAddress };
