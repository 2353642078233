import { ConsultCallInputs } from "../../../util/constants/consultCall";

export type confirmInfoModalHasRequiredPropertiesProps = {
  location: google.maps.GeocoderResult | null;
  defaultAddress: string;
  firstName: string;
  lastName: string;
  consultCallInput: ConsultCallInputs | "";
  referralSource: string;
  phoneNumberIsValid: boolean;
  emailIsValid: boolean;
  passwordIsValid: boolean;
  passwordConfirmIsValid: boolean;
};

export const confirmInfoModalHasRequiredProperties = ({
  location,
  defaultAddress,
  firstName,
  lastName,
  consultCallInput,
  referralSource,
  phoneNumberIsValid,
  emailIsValid,
  passwordIsValid,
  passwordConfirmIsValid,
}: confirmInfoModalHasRequiredPropertiesProps): boolean => {
  const hasName = !!firstName.length && !!lastName.length;
  const hasAddress = !!location || !!defaultAddress;

  return (
    hasAddress &&
    hasName &&
    consultCallInput.length > 0 &&
    referralSource.length > 0 &&
    phoneNumberIsValid &&
    emailIsValid &&
    passwordIsValid &&
    passwordConfirmIsValid
  );
};
