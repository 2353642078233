export function initializeZendeskWidget() {
  const currentScript = document.getElementById("ze-snippet");
  const currentURL = window.location.pathname;
  const script = document.createElement("script");
  script.id = "ze-snippet";
  script.src =
    "https://static.zdassets.com/ekr/snippet.js?key=44eb05b9-e258-40b2-9438-497c23532e4f";

  if (currentScript && currentURL.startsWith("/onboard")) {
    document.head.removeChild(currentScript);
  }

  if (!currentURL.startsWith("/onboard")) {
    document.head.appendChild(script);
  }
}
