import React, { FC, useState, useEffect, useContext } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import firebase from "firebase/compat/app";
import { UserCtx, OnboardCtx, ProfileCtx } from "../../util";
import { YZButton } from "../buttons";
import { PlacesAddressForm } from "../designProfile/PlacesAddressForm";
import {
  IAddressObject,
  parseGeocodeLocationToAddress,
} from "../../util/functions/parseGeocodeLocationToAddress";
import { formatAddress } from "../../util/functions/formatAddress";
import { YZTypography } from "@yardzen-inc/react-common";
import {
  ConsultCallInputs,
  CONSULT_CALL_I_DONT_KNOW,
  CONSULT_CALL_NO,
  CONSULT_CALL_YES,
} from "../../util/constants/consultCall";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { HowDidYouHearAboutYZ } from "../utility/HowDidYouHearAboutYZ";
import { useDesignProfileCtx } from "../designProfile/DesignProfileCtx";
import { confirmInfoModalHasRequiredProperties } from "./util/confirmInfoModalHasRequiredProperties";
import { confirmInfoModalHandleSubmit } from "./util/confirmInfoModalHandleSubmit";
import { confirmInfoModalGetInformationFromProfile } from "./util/confirmInfoModalGetInformationFromProfile";
import { MaskedPhoneInput } from "../shared/MaskedPhoneInput";
import { useTreatment } from "@yardzen-inc/react-split";
import {
  MARKETING_PHONE_COLLECTION_ONBOARDING,
  MOVE_ACCOUNT_CREATION,
} from "../../util/Split/splitTreatments";
import { ATTENTIVE_URLS } from "../../pages/designProfile/constants/attentiveUrls";
import { UpdateProfileDto } from "../../api/apiTypes";
import { validatePhoneNumber } from "../../pages/designProfile/util/validatePhoneNumber";
import { useSegment } from "../../util/Segment";

interface ConfirmInformationFormProps {
  handleUpdateDesignProfile: (
    pathParams: string,
    updateData: UpdateProfileDto
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  button: {
    width: "100%",
  },
  additionalQuestionsContainer: {
    display: "flex",
    marginLeft: "5px",
    marginTop: "15px",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  consultCallError: {
    color: "#F44636",
  },
  consultCallRadioGroup: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  referralContainer: {
    width: "275px",

    [theme.breakpoints.down("xs")]: {
      margin: "20px 0",
      width: "auto",
      maxWidth: "275px",
    },
  },
}));

const hasMinimum6CharsRegExp = /.{6,}/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const ConfirmInformationForm: FC<ConfirmInformationFormProps> = ({
  handleUpdateDesignProfile,
}) => {
  const hasLoginProvider = !!firebase.auth().currentUser?.providerData?.length;
  const firebaseEmail = firebase.auth().currentUser?.email;
  const urlParams = new URLSearchParams(window.location.search);
  const byProxy = urlParams.get("byProxy") === "true";
  const moveAccountCreatingTest =
    useTreatment(MOVE_ACCOUNT_CREATION) || byProxy;

  const user = useContext(UserCtx);
  const { designProfile, updateDesignProfile } = useDesignProfileCtx();
  const {
    root,
    button,
    additionalQuestionsContainer,
    consultCallRadioGroup,
    consultCallError,
    referralContainer,
  } = useStyles();
  const profile = React.useContext(ProfileCtx);
  const {
    state: { projectId },
  } = useContext(OnboardCtx);
  const [error, setError] = useState<null | string>(null);
  const [loc, setLoc] = useState<google.maps.GeocoderResult | null>(null);
  const { setInformationVerified, setOnboardContextError } = useContext(
    OnboardCtx
  );

  const [email, setEmail] = useState<string>(firebaseEmail || "");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);
  const [passwordConfirmIsValid, setPasswordConfirmIsValid] = useState<boolean>(
    false
  );
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [consultCallInput, setConsultCallInput] = useState<
    ConsultCallInputs | ""
  >("");
  const [information, setInformation] = useState<IAddressObject>({
    street: "",
    city: "",
    state: "",
    zip: "",
    formattedAddress: "",
    country: "",
    county: "",
    latLng: { lat: 0, lng: 0 },
  });
  const [referralSource, setReferralSource] = React.useState<string>("");
  const phoneNumberIsValid =
    phoneNumber.length > 0 && !!validatePhoneNumber(phoneNumber);

  const showMarketingPhoneCollectionSplit = useTreatment(
    MARKETING_PHONE_COLLECTION_ONBOARDING
  );

  const defaultAddress =
    formatAddress((profile as unknown) as IAddressObject) ||
    formatAddress((designProfile as unknown) as IAddressObject);

  const requirementsComplete = confirmInfoModalHasRequiredProperties({
    location: loc,
    defaultAddress,
    firstName,
    lastName,
    consultCallInput,
    referralSource,
    phoneNumberIsValid:
      phoneNumberIsValid || !showMarketingPhoneCollectionSplit,
    emailIsValid:
      emailIsValid || !moveAccountCreatingTest || !!hasLoginProvider,
    passwordIsValid:
      passwordIsValid || !moveAccountCreatingTest || !!hasLoginProvider,
    passwordConfirmIsValid:
      passwordConfirmIsValid || !moveAccountCreatingTest || !!hasLoginProvider,
  });

  const [
    hasAcceptedToDesignTipsViaText,
    setHasAcceptedToDesignTipsViaText,
  ] = useState(false);
  const segment = useSegment();

  useEffect(() => {
    const passwordIsValid =
      password.length > 0 && !!password.match(hasMinimum6CharsRegExp);
    const passwordConfirmIsValid =
      passwordConfirm.length > 0 && password === passwordConfirm;
    setPasswordIsValid(passwordIsValid);
    setPasswordConfirmIsValid(passwordConfirmIsValid);
    const errors = [];
    if (!passwordIsValid) errors.push("Password must be at least 6 characters");
    if (!passwordConfirmIsValid) errors.push("Passwords must match");
    setPasswordError(errors.join(", "));
  }, [password, passwordConfirm]);

  useEffect(() => {
    setEmailIsValid(email.length > 0 && !!email.match(emailRegex));
  }, [email]);

  useEffect(() => {
    requirementsComplete && setError(null);
  }, [requirementsComplete]);

  useEffect(() => {
    if (loc) {
      setInformation(parseGeocodeLocationToAddress(loc));
    }
  }, [loc]);

  useEffect(() => {
    if (!profile) {
      return;
    } else {
      confirmInfoModalGetInformationFromProfile({
        profile,
        setFirstName,
        setLastName,
        setInformation,
        setPhoneNumber,
      });
    }
  }, [profile]);

  useEffect(() => {
    setReferralSource(designProfile?.referralSource || "");
  }, [designProfile?.referralSource]);

  useEffect(() => {
    const setLocation = async () => {
      if (!window.google || !designProfile?.contactInformation?.placeId) return;
      try {
        const geoLoc = await geocodeByPlaceId(
          designProfile.contactInformation.placeId
        );
        setLoc(geoLoc[0]);
      } catch (error) {
        console.error(error);
      }
    };

    setLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designProfile?.contactInformation?.placeId, window.google]);

  if (!profile) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={5}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box className={root}>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            value={firstName}
            onChange={evt => setFirstName(evt.target.value)}
            label="First Name"
            error={!firstName.length}
            fullWidth
            variant="outlined"
            size="small"
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs>
          <TextField
            value={lastName}
            onChange={evt => setLastName(evt.target.value)}
            label="Last Name"
            error={!lastName.length}
            fullWidth
            variant="outlined"
            size="small"
            autoComplete="family-name"
          />
        </Grid>
      </Grid>
      {moveAccountCreatingTest && !hasLoginProvider && (
        <>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs>
              <TextField
                value={email}
                onChange={evt => setEmail(evt.target.value)}
                label="Email"
                error={!emailIsValid}
                fullWidth
                variant="outlined"
                size="small"
                type="email"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs>
              <TextField
                value={password}
                onChange={evt => setPassword(evt.target.value)}
                label="Password"
                error={!passwordIsValid}
                fullWidth
                variant="outlined"
                size="small"
                type="password"
              />
            </Grid>
            <Grid item xs>
              <TextField
                value={passwordConfirm}
                onChange={evt => setPasswordConfirm(evt.target.value)}
                label="Retype Password"
                error={!passwordConfirmIsValid}
                fullWidth
                variant="outlined"
                size="small"
                type="password"
              />
            </Grid>
          </Grid>
          <Box mb={1.5}>
            <YZTypography style={{ fontSize: "14px" }}>
              {passwordError}
            </YZTypography>
          </Box>
        </>
      )}

      {showMarketingPhoneCollectionSplit && (
        <Box
          mt={2}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          <MaskedPhoneInput
            value={phoneNumber}
            handleChange={evt =>
              !!Number(evt.target.value) || evt.target.value === ""
                ? setPhoneNumber(evt.target.value)
                : null
            }
            handleKeyPress={() => {}}
            label={"Mobile Phone Number"}
            errorText={
              phoneNumberIsValid ? "" : "Please enter a valid phone number"
            }
            helperText=""
            setFocused={() => {}}
            titleTextAlign="left"
          />

          <Box textAlign="left" flexDirection="column" mt={1}>
            <Box flexDirection="row" justifyItems="left" alignItems="center">
              <Checkbox
                style={{ padding: "3px 6px" }}
                value={hasAcceptedToDesignTipsViaText}
                onChange={event =>
                  setHasAcceptedToDesignTipsViaText(event.target.checked)
                }
              />
              <YZTypography variant="caption">
                Get design tips, inspiration, and offers via text.
              </YZTypography>
            </Box>
            <YZTypography variant="caption">
              By checking this box, you agree to receive recurring automated
              promotional and personalized marketing text messages from Yardzen.
              Reply HELP for help and STOP to cancel. Message frequency varies.
              Message and data rates may apply. I have read and agreed to the{" "}
              <Link
                style={{ color: "black", textDecoration: "underline" }}
                href={ATTENTIVE_URLS.TERMS}
                target="_blank"
              >
                Terms
              </Link>{" "}
              and{" "}
              <Link
                style={{ color: "black", textDecoration: "underline" }}
                href={ATTENTIVE_URLS.PRIVACY}
                target="_blank"
              >
                Privacy Policy
              </Link>
            </YZTypography>
          </Box>
        </Box>
      )}
      <Box className={additionalQuestionsContainer}>
        <FormControl>
          <FormLabel>
            <YZTypography
              className={consultCallInput.length ? "" : consultCallError}
              data-testid="consult-call-label"
            >
              Did you have a consult call?
            </YZTypography>
          </FormLabel>
          <RadioGroup
            aria-label="consult-call"
            name="consult-call"
            value={consultCallInput}
            onChange={e =>
              setConsultCallInput(e.target.value as ConsultCallInputs)
            }
            className={consultCallRadioGroup}
          >
            <FormControlLabel
              value={CONSULT_CALL_YES}
              control={<Radio />}
              label={CONSULT_CALL_YES}
            />
            <FormControlLabel
              value={CONSULT_CALL_NO}
              control={<Radio />}
              label={CONSULT_CALL_NO}
            />
            <FormControlLabel
              value={CONSULT_CALL_I_DONT_KNOW}
              control={<Radio data-testid="consult-call-radio-btn" />}
              label={CONSULT_CALL_I_DONT_KNOW}
            />
          </RadioGroup>
        </FormControl>
        <div className={referralContainer}>
          <HowDidYouHearAboutYZ
            selectedOption={referralSource}
            setSelectedOption={setReferralSource}
            error={false}
            redText={!referralSource}
            requiredText={true}
            containerStyle={{ marginTop: "0px" }}
            textStyle={{ color: "#F44636", marginBottom: "5px" }}
            smallVariant={true}
          />
        </div>
      </Box>
      <PlacesAddressForm
        defaultAddress={
          formatAddress((profile as unknown) as IAddressObject) ||
          formatAddress((designProfile as unknown) as IAddressObject)
        }
        onSubmitAddress={async address => {
          setLoc(null);
          setInformation({ ...address });
        }}
        handleAddressSelection={async ({ value }) => {
          const geoLocation = (await geocodeByPlaceId(value.place_id))[0];

          setLoc(geoLocation);
        }}
      />
      <Box display="flex" flexDirection="column" justifyContent="center" pt={1}>
        <Box width="100%">
          <YZButton
            className={button}
            onClick={() =>
              confirmInfoModalHandleSubmit({
                user,
                requirementsComplete,
                referralSource,
                information,
                firstName,
                lastName,
                phone: phoneNumber,
                consultCallInput,
                projectId,
                hasUserAcceptedSMSCommunications: hasAcceptedToDesignTipsViaText,
                setInformationVerified,
                setError,
                setOnboardContextError,
                designProfile,
                profile,
                updateDesignProfile,
                handleUpdateProfile: handleUpdateDesignProfile,
                segment,
                email,
                password,
                completeAccountCreation:
                  !!moveAccountCreatingTest && !hasLoginProvider,
              })
            }
            id="confirm-button"
          >
            Confirm
          </YZButton>
          {error && (
            <YZTypography
              color="error"
              variant="caption"
              data-testid="confirm-error"
            >
              {error}
            </YZTypography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { ConfirmInformationForm };
